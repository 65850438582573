<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Prestform from "./components/Prestform";
import { fetchPrestatairesListApi } from "@/api/common";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {
   props: {
    refreshTableEvent: {
      type: Boolean,
      default: false,
    },
  },

   import: {fetchPrestatairesListApi},
    page: {
        title: "Employées Prestataire",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader,Prestform,
     Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      DatasetSearch,
      DatasetShow,},
    data() {
    return {
      currentSelectedEmp:undefined,
      selectedEmp: null,
      tableData: [],
      title: "Employées Prestataire",
      List_Emp: [],
      rows:'',
      active:false,
      selectedRows:[],
      Emp:undefined,
      refrechTable:undefined,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Gestion Du Personnel",
          to: { name: 'master_data.referentiels.users' }
        },
        {
          text: "Employées Prestataires",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      cols: [
         {
          name: "Prestataire ",
          field: "supplier",
          sort: ''
        },
         {
          name: "Matricule ",
          field: "company_adr",
          sort: ''
        },
        {
          name: "Nom d'employe",
          field: "first_name",
          sort: ''
        },
        {
          name: "Email",
          field: "email",
          sort: ''
        },
        {
          name: "Télèphone",
          field: "phone",
          sort: ''
        },
        {
          name: "Adresse",
          field: "business_address",
          sort: ''
        },
      ],
      selectedIndex:-1,
    };
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;

    // fetch divisions from api

    this.fetchEmpList();
  },
  watch:{
    refrechTable:{
      handler(val){
        console.log(val);
        this.fetchEmpList();
        this.refrechTable=false;
      }
    },
    refreshTableEvent() {
      if (this.refreshTableEvent) {
        this.fetchEmpList();
      }
    },

      selectedRows(){
        if(this.selectedRows.length){
          //console.log(this.selectedRows[0]);
         this.selectedEmp = this.selectedRows[0];
        }
        else{
          this.selectedEmp =[];
        }
      },
      Emp:{
          handler(val){       
            this.List_Emp.push({
                Emp_first_name:val.Emp_first_name,
                Emp_last_name:val.Emp_last_name,
                Emp_sexe:val.Emp_sexe,
                Emp_phone:val.Emp_phone,
                Emp_mobile:val.Emp_mobile,
                Emp_email:val.Emp_email,
                Emp_business_address:val.Emp_business_address,
                Emp_n_cin:val.Emp_n_cin,

                Emp_n_passport:val.Emp_n_passport,
                Emp_marital:val.Emp_marital,
                Emp_employee_number:val.Emp_employee_number,
                Emp_analytic_account:val.Emp_analytic_account,
                Emp_supplier_id:val.Emp_supplier_id,
            });
            
          }
          
          
      }
  },
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
    fetchEmpList(){
      fetchPrestatairesListApi()
      .then(res => (this.List_Emp = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getEmp(){
      this.$http.get('/referentiel/employees_prestataire/list')
      .then(response => {
        console.log(response.data);
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshEmp(){
      this.getEmp();
    },
     deleteEmp(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/referentiel/employees_prestataire/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchEmpList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

     editEmp(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/referentiel/employees_prestataire/getSingleEmpPrestataireData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var employe = res.data.original.data.employe;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedEmp = employe;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadEmpDataToFormComponent(selectedEmp){
      if(selectedEmp){
        this.fetchEmpDetails(selectedEmp.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedEmp.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedEmp.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedEmp.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedEmp.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedEmp.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(employe){

      this.currentSelectedEmp = employe;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshCompanyTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste employees Prestataires</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset v-slot="{ ds }" :ds-data="List_Emp">
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.supplier.prestname }}</td>
                                <td>{{ row.employee_number }}</td>
                                <td>{{ row.first_name }} {{row.last_name}}</td>
                                <td>{{ row.email }}</td>
                                <td>{{ row.phone }}</td>
                                <td>{{ row.business_address }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
                <!-- <table class="table table-centered table-nowrap"> -->
                  <!-- <v-table
                    class="table table-hover"
                    :data="List_Emp"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Nom d'employe</th>
                      <th>Email</th>
                      <th>Télèphone</th>
                      <th>Adresse</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        
                        {{ row.first_name }} {{row.last_name}}
                      </td>
                      <td>{{ row.email }}</td>
                      <td>{{ row.phone }}</td>
                      <td>{{ row.business_address }}</td>
                    </v-tr>
                  </tbody>
                </v-table> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <Prestform :base="selectedRows"  :employe="currentSelectedEmp" @refrechTable="refrechTable=$event" :selectedEmp="selectedEmp"  />
        </div>
      </div>
    </div>
    </Layout>
</template>